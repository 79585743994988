import { useEffect, useState } from 'react';

import { eventBus } from '@npm/core/ui/utils/eventBus';

import { ZendeskSupportModal } from './ZendeskSupportModal';
import { type Ticket } from './ZendeskSupportModal.types';

export const ZendeskContactSupport = () => {
  const [openModal, setOpenModal] = useState(false);
  const [zendeskTicket, setZendeskTicket] = useState<Ticket>();

  useEffect(() => {
    return eventBus.on<Ticket>('REQUEST_SUPPORT', ticket => {
      setOpenModal(true);
      setZendeskTicket(ticket);
    });
  }, []);

  return (
    <ZendeskSupportModal
      isOpen={openModal}
      key={`zendesk-support-modal-${openModal}`}
      ticket={zendeskTicket}
      onCancel={() => setOpenModal(false)}
    />
  );
};
